import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { AiFillTwitterCircle } from "react-icons/ai";
import {
  RiEarthFill,
  RiTelegramFill,
  RiDiscordFill,
  RiFacebookCircleFill,
  RiGithubFill,
  RiInstagramFill,
  RiRedditFill,
} from "react-icons/ri";
import { useTokenInfo } from "../pages/launchpadApply/SaleList/helper/useStats";

import question from "../images/question.png";

const AirdropCard = ({ chainId, rowdata, index, cs }) => {
  const [social, setSocial] = useState([]);
  const [startTime, setStartTime] = useState(0);

  const [isValidUrl, setIsValidUrl] = useState(true);
  const handleLoad = useCallback(() => {
    setIsValidUrl(true);
  }, []);
  const handleError = useCallback(() => {
    setIsValidUrl(false);
  }, []);

  const tokenInfo = useTokenInfo(rowdata.token, chainId);
  useEffect(() => {
    if (rowdata.poolDetails !== undefined) {
      let details = rowdata.poolDetails.toString().split("$#$");
      const _social = {
        logourl: details[0],
        bannerurl: details[1],
        website: details[2],
        blockstar: details[3],
        facebook: details[4],
        twitter: details[5],
        github: details[6],
        telegram: details[7],
        instagram: details[8],
        discord: details[9],
        reddit: details[10],
        youtube: details[11],
        brief: details[12],
      };
      setSocial(_social);
    }

    if (rowdata.startTime !== undefined && rowdata.startTime !== 0) {
      const _startTime = new Date(rowdata.startTime * 1000);
      setStartTime(_startTime);
    }
  }, [rowdata]);

  const claimedPercentage = useMemo(() => {
    if (rowdata.totalAllocationAmount === 0) return 0;
    return (rowdata.totalClaimedAmount / rowdata.totalAllocationAmount) * 100;
  }, [rowdata]);

  return (
    <div
      className="col-12 col-md-6 col-lg-4 item explore-item mb-4"
      key={index}
    >
      <div className="card project-card">
        <div
          className="card-header py-4 px-2"
          style={{
            background: `url(${social.bannerurl}), linear-gradient(45deg, #10161d, transparent)`,
          }}
        >
          <div className="d-flex">
            <div className="project-logo ml-2">
              {isValidUrl && (
                <img
                  src={social.logourl}
                  width="50px"
                  alt="logo"
                  onLoad={handleLoad}
                  onError={handleError}
                />
              )}
              {!isValidUrl && <img src={question} width="50px" alt="logo" />}
            </div>
          </div>
          <div className="d-flex flex-column align-items-end gap-4">
            <span>
              <h4 className="tag-btn text-uppercase text-center bg-blue">
                Airdrop
              </h4>
            </span>
            <span>
              <h4
                className={
                  "tag-btn tag-left-circle text-uppercase text-center bg-" +
                  rowdata.poolState
                }
              >
                {rowdata.poolState}
              </h4>
            </span>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Link to={`/airdrop-details/${rowdata.poolAddress}`}>
                <h4 className="mx-0 mt-2 mb-0">
                  {tokenInfo.name ? tokenInfo.name : ""}
                </h4>
              </Link>
            </div>
            <div className="social-icons">
              {social.website && (
                <a target="_blank" rel="noreferrer" href={social.website}>
                  <RiEarthFill />
                </a>
              )}
              {social.twitter && (
                <a target="_blank" rel="noreferrer" href={social.twitter}>
                  <AiFillTwitterCircle />
                </a>
              )}
              {social.facebook && (
                <a target="_blank" rel="noreferrer" href={social.facebook}>
                  <RiFacebookCircleFill />
                </a>
              )}
              {social.instagram && (
                <a target="_blank" rel="noreferrer" href={social.instagram}>
                  <RiInstagramFill />
                </a>
              )}
              {social.reddit && (
                <a target="_blank" rel="noreferrer" href={social.reddit}>
                  <RiRedditFill />
                </a>
              )}
              {social.telegram && (
                <a target="_blank" rel="noreferrer" href={social.telegram}>
                  <RiTelegramFill />
                </a>
              )}
              {social.discord && (
                <a target="_blank" rel="noreferrer" href={social.discord}>
                  <RiDiscordFill />
                </a>
              )}
              {social.github && (
                <a target="_blank" rel="noreferrer" href={social.github}>
                  <RiGithubFill />
                </a>
              )}
            </div>
          </div>
          <div className="date-detail mt-3">
            <div className="d-flex justify-content-between">
              <span>Start Time:</span>
              <span className="ml-1">
                {startTime === 0
                  ? "--"
                  : dateFormat(startTime, "yyyy-mm-dd HH:MM")}
              </span>
            </div>
            {/* <div className="d-flex justify-content-between">
              <span>End Time:</span>
              <span className="ml-1">
                {dateFormat(endTime, "yyyy-mm-dd HH:MM")}
              </span>
            </div> */}
          </div>
          <div className="item-progress my-4">
            <div className="progress mt-md-1 ">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${claimedPercentage.toFixed(2)}%`,
                }}
                aria-valuenow={claimedPercentage.toFixed(2)}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <span>{claimedPercentage.toFixed(2)}%</span>
            </div>
          </div>
          <div>
            <Link
              to={`/airdrop-details/${rowdata.poolAddress}`}
              className="btn btn-smaller w-100"
            >
              view pool
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirdropCard;
