import React from "react";
import styled from "styled-components";

import ChainSelector from "./ChainSelector";
import SearchBox from "./SearchBox";
import Connect from "./Connect";

import "./Header.css";

const LogoContainer = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  @media screen and (max-width: 425px) {
    border-right: 1px solid #adadad33;
  }
`;

const ComponentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 425px) {
    justify-content: end;
  }
`;

export default function Header() {
  return (
    <React.Fragment>
      <header id="header">
        <LogoContainer>
          <img
            src={require("../images/logo.png").default}
            alt="Brand Logo"
            width="40px"
          />
        </LogoContainer>
        <ComponentContainer>
          <ChainSelector />
          <SearchBox />
          <Connect />
        </ComponentContainer>
      </header>
    </React.Fragment>
  );
}
