import React from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";

import Dropdown from "react-bootstrap/Dropdown";

import { supportNetwork } from "../hooks/network";

const StyledDropDown = styled(Dropdown)`
  display: block;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export default function ChainSelector() {
  const context = useWeb3React();
  const { chainId } = context;

  const switchNetwork = (networkid) => {
    try {
      // @ts-ignore
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkid.toString(16)}` }],
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <StyledDropDown>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="d-flex align-items-center"
        >
          <img
            src={
              supportNetwork[chainId]
                ? supportNetwork[chainId].image
                : supportNetwork["default"].image
            }
            alt="Switch Network"
            className="mr-2 hide-on-mobile"
            width="12"
          />
          <span className="mr-1 hide-on-mobile">
            {chainId && supportNetwork[chainId]
              ? supportNetwork[chainId].name
              : supportNetwork["default"].name}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(supportNetwork).map(function (key) {
            if (key !== "default")
              return (
                <Dropdown.Item
                  key={key}
                  className="d-flex align-items-center"
                  onClick={() => {
                    switchNetwork(supportNetwork[key].chainId);
                  }}
                >
                  <img
                    src={supportNetwork[key].image}
                    width="15px"
                    className="mr-2"
                    alt="Chain Logo"
                  />
                  <span>{supportNetwork[key].name}</span>
                </Dropdown.Item>
              );
          })}
        </Dropdown.Menu>
      </StyledDropDown>
    </React.Fragment>
  );
}
