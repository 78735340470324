import binanceCoin from "../images/binance-coin.png";
import avalancheCoin from "../images/avalanche.svg";
import polygonCoin from "../images/polygon.svg";

export const supportNetwork = {
  56: {
    name: "BSC Mainnet",
    chainId: 56,
    rpc: "https://bsc-dataseed.binance.org",
    image: binanceCoin,
    symbol: "BNB",
    subgraph: "https://api.thegraph.com/subgraphs/name/kato114/slappad-bsc",
    explorer: "https://bscscan.com",
  },
  137: {
    name: "Polygon",
    chainId: 137,
    rpc: "https://polygon-rpc.com",
    image: polygonCoin,
    symbol: "MATIC",
    subgraph: "https://api.thegraph.com/subgraphs/name/kato114/slappad-polygon",
    explorer: "https://polygonscan.com/",
  },
  43114: {
    name: "Avalanche",
    chainId: 43114,
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    image: avalancheCoin,
    symbol: "AVAX",
    subgraph:
      "https://api.thegraph.com/subgraphs/name/kato114/slappad-avalanche",
    explorer: "https://snowtrace.io/",
  },
  // 97: {
  //   name: "BSC Testnet",
  //   chainId: 97,
  //   rpc: "https://data-seed-prebsc-1-s2.binance.org:8545",
  //   image: binanceCoin,
  //   symbol: "TBNB",
  //   subgraph: "https://api.thegraph.com/subgraphs/name/cryptolteam/slappad",
  //   explorer: "https://testnet.bscscan.com",
  // },
  default: {
    name: "BSC Mainnet",
    chainId: 56,
    rpc: "https://bsc-dataseed.binance.org",
    image: binanceCoin,
    symbol: "BNB",
    subgraph: "https://api.thegraph.com/subgraphs/name/kato114/slappad-bsc",
    explorer: "https://bscscan.com",
  },
};

export const RPC_URLS = {
  56: "https://bsc-dataseed.binance.org",
  137: "https://polygon-rpc.com",
  43114: "https://api.avax.network/ext/bc/C/rpc",
  97: "https://data-seed-prebsc-1-s2.binance.org:8545",
};

export const getChainInfo = (queryChainId, chainId) => {
  const _chainId_ = supportNetwork[queryChainId ? queryChainId : chainId]
    ? supportNetwork[queryChainId ? queryChainId : chainId].chainId
    : supportNetwork["default"].chainId;
  const _chainName_ = supportNetwork[_chainId_].name;
  const _explorer_ = supportNetwork[_chainId_].explorer;
  return { _chainId_, _chainName_, _explorer_ };
};

export const getSubgraphUrl = (chainId) => {
  const _chainId_ = supportNetwork[chainId]
    ? supportNetwork[chainId].chainId
    : supportNetwork["default"].chainId;
  return supportNetwork[_chainId_].subgraph;
};
