import React, { useState } from "react";

import TokenInput from "../../component/TokenInput";
import BoostSetting from "./component/BoostSetting";

export default function Staking() {
  const [value, setValue] = useState({
    tokenAddress: "",
    tokenSymbol: "",
    tokenName: "",
    tokenDecimal: "",
  });

  return (
    <React.Fragment>
      <section id="staking" className="explore-area prev-project-area">
        <div className="px-3">
          <div className="intro">
            <div className="intro-content text-center">
              <span className="intro-text">Create Staking</span>
            </div>
          </div>
          <div className="signup-step-container my-5">
            <div className="card stepcard mt-5 pt-5">
              <div className="card-body">
                <form className="login-box" action="#">
                  <div className="tab-content">
                    <TokenInput value={value} setValue={setValue} />
                  </div>
                  <div className="form-group">
                    <div className="col-md-12 mt-4 mb-0">
                      <label>
                        Min Staking Period
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-9 mb-0">
                        <input
                          className="form-control"
                          // onChange={(e) => onChangeInput(e)}
                          value={value.softcap}
                          type="text"
                          name="softcap"
                          placeholder="e.g. 1000"
                        />
                      </div>
                      <div className="col-md-3 mb-0">
                        <select
                          className="form-select"
                          defaultValue="1"
                          name="refund"
                          aria-label="Default select example"
                          style={{ height: "38px" }}
                        >
                          <option value="0">days</option>
                          <option value="1">weeks</option>
                          <option value="1">months</option>
                          <option value="1">years</option>
                        </select>
                      </div>
                    </div>
                    {/* <small className="text-danger">{error.softcap}</small> */}
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6 mb-0">
                      <div className="form-group">
                        <label>
                          Min Staking Amount
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control"
                          // onChange={(e) => onChangeInput(e)}
                          value={value.hardcap}
                          type="text"
                          name="hardcap"
                          placeholder="e.g. 1000"
                        />
                        {/* <small className="text-danger">{error.hardcap}</small> */}
                        <br />
                      </div>
                    </div>
                    <div className="col-md-6 mb-0">
                      <div className="form-group">
                        <label>
                          Max Staking Amount
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-control"
                          // onChange={(e) => onChangeInput(e)}
                          value={value.minbuy}
                          type="text"
                          name="minbuy"
                          placeholder="e.g. 1000"
                        />
                        {/* <small className="text-danger">{error.minbuy}</small> */}
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-12 mt-4 mb-0">
                      <label>
                        Boost Settings
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <BoostSetting />
                    <BoostSetting />
                    <div className="row">
                      <div className="col-md-12">
                        <button className="w-100 btn btn-bordered">
                          + Add Setting
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="btn">Create</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
