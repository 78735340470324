import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import "../launchpadApply/index.css";
import { useWeb3React } from "@web3-react/core";
import Button from "react-bootstrap-button-loader";
import { contract } from "../../hooks/constant";
import { getContract } from "../../hooks/contractHelper";
import tokenAbi from "../../json/token.json";
import MultiSenderAbi from "../../json/MultiSender.json";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { getWeb3 } from "../../hooks/connectors";
import { toast } from "react-toastify";
import { MulticallContractWeb3 } from "../../hooks/useContracts";
import LoaderComponent from "../../component/LoaderComponent";

export default function Multisender() {
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainId");
  const { chainId, account, library } = useWeb3React();
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [allowance, setAllowance] = useState(0);
  const [tokenDecimal, setTokenDecimal] = useState("");
  const [txLoading, setTxLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("siper: fetch: ");
    if (!tokenAddress) {
      setIsLoading(false);
      return;
    }
    if (tokenAddress.length === 0) {
      setIsLoading(false);
      return;
    }

    const fetchAllowance = async () => {
      try {
        const web3 = getWeb3(chainId);
        let checkSumaddress = await web3.utils.toChecksumAddress(tokenAddress);
        let isCode = await web3.eth.getCode(tokenAddress);
        console.log("siper: fetch: 111");
        if (!checkSumaddress || isCode === "0x") {
          setError("Please Enter Valid Address!");
          setIsLoading(false);
          setTokenName("");
          setTokenSymbol("");
          setTokenDecimal("");
          setAllowance(0);
          return;
        }

        const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
        let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        const multisenderAddress = contract[chainId]
          ? contract[chainId].multiSender
          : contract["default"].multiSender;
        const result = await mc.aggregate([
          tokenContract.methods.name(),
          tokenContract.methods.symbol(),
          tokenContract.methods.decimals(),
          tokenContract.methods.allowance(account, multisenderAddress),
          tokenContract.methods.balanceOf(account),
        ]);
        setTokenName(result[0]);
        setTokenSymbol(result[1]);
        setTokenDecimal(result[2]);
        setAllowance(formatUnits(result[3], result[2]));
        setError("");
      } catch (error) {
        setIsLoading(false);
        setTokenName("");
        setTokenSymbol("");
        setTokenDecimal("");
        setAllowance(0);
        setError("Please Enter Valid Address!");
      }
      setIsLoading(false);
    };
    fetchAllowance();
  }, [tokenAddress, account, chainId, queryChainId]);

  const handleApprove = async (e) => {
    e.preventDefault();
    if (account) {
      if (chainId) {
        try {
          if (tokenAddress && tokenDecimal > 0) {
            setTxLoading(true);
            let multisenderAddress = contract[chainId]
              ? contract[chainId].multiSender
              : contract["default"].multiSender;
            let tokenContract = getContract(tokenAbi, tokenAddress, library);
            let amount = parseUnits(totalSendValue.toString(), tokenDecimal);

            let tx = await tokenContract.approve(multisenderAddress, amount, {
              from: account,
            });
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 10000)
            );
            toast.promise(resolveAfter3Sec, {
              pending: "Waiting for confirmation 👌",
            });
            var interval = setInterval(async function () {
              let web3 = getWeb3(chainId);
              var response = await web3.eth.getTransactionReceipt(tx.hash);
              if (response != null) {
                clearInterval(interval);
                if (response.status === true) {
                  toast.success(
                    "success ! your last transaction is success 👍"
                  );
                  setTxLoading(false);
                  setAllowance(formatUnits(amount, tokenDecimal));
                } else if (response.status === false) {
                  toast.error("error ! Your last transaction is failed.");
                  setTxLoading(false);
                } else {
                  toast.error("error ! something went wrong.");
                  setTxLoading(false);
                }
              }
            }, 5000);
          } else {
            toast.error("Please enter valid token address !");
            setTxLoading(false);
          }
        } catch (err) {
          toast.error(err.reason);
          setTxLoading(false);
        }
      } else {
        toast.error("Please select Smart Chain Network !");
        setTxLoading(false);
      }
    } else {
      toast.error("Please Connect Wallet!");
      setTxLoading(false);
    }
  };

  const handleSendTokens = async (e) => {
    e.preventDefault();
    if (account) {
      if (chainId) {
        try {
          setTxLoading(true);
          let multisenderAddress = contract[chainId]
            ? contract[chainId].multiSender
            : contract["default"].multiSender;
          let multiSenderContract = getContract(
            MultiSenderAbi,
            multisenderAddress,
            library
          );

          const receivers = allocations.map((item) => item.address);
          const amounts = allocations.map((item) =>
            parseUnits(item.value.toString(), tokenDecimal)
          );

          let tx = await multiSenderContract.multiSender(
            tokenAddress,
            account,
            receivers,
            amounts,
            {
              from: account,
            }
          );
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 10000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation 👌",
          });
          var interval = setInterval(async function () {
            let web3 = getWeb3(chainId);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval);
              if (response.status === true) {
                toast.success("success ! your last transaction is success 👍");
                setTxLoading(false);
                //   setAllowance(formatUnits(amount, tokenDecimal));
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setTxLoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setTxLoading(false);
              }
            }
          }, 5000);
        } catch (err) {
          toast.error(err.reason);
          setTxLoading(false);
        }
      } else {
        toast.error("Please select Smart Chain Network !");
        setTxLoading(false);
      }
    } else {
      toast.error("Please Connect Wallet!");
      setTxLoading(false);
    }
  };

  const handleInput = (e) => {
    setTokenAddress(e.target.value);
  };

  const [allocations, setAllocations] = useState([]);
  const [allocationValue, setAllocationValue] = useState();

  const handleAllocationChange = (e) => {
    setAllocationValue(e.target.value);
    const value = e.target.value.split("\n");
    const formatValue = value.map((item) => {
      let itemData = [];
      if (item.includes(",")) {
        itemData = item.split(",");
      } else {
        itemData = item.split(" ");
      }
      return { address: itemData[0], value: Number(itemData[1]) };
    });
    setAllocations(formatValue);
  };

  const totalSendValue = useMemo(() => {
    if (allocations.length > 0) {
      return allocations.reduce((sumValue, a) => sumValue + a.value, 0);
    }
    return 0;
  }, [allocations]);

  const shouldApprove = useMemo(() => {
    return allowance < totalSendValue;
  }, [totalSendValue, allowance]);

  return (
    <React.Fragment>
      <section className="explore-area create-token">
        <div className="px-3">
          <div className="intro">
            <div className="intro-content text-center">
              <span className="intro-text">Multi Sender</span>
            </div>
          </div>
          <div className="my-5">
            <div className="card stepcard">
              <div className="card-body">
                <form className="login-box">
                  <div className="">
                    <div
                      className={`tab-pane active mt-3`}
                      role="tabpanel"
                      id="step1"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Token address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder="0x"
                              onChange={(e) => {
                                handleInput(e);
                                setIsLoading(true);
                              }}
                            />
                            <small className="text-danger">{error}</small>
                          </div>

                          {isLoading === true ? (
                            <LoaderComponent status={isLoading} />
                          ) : (
                            tokenName !== "" &&
                            tokenDecimal &&
                            tokenSymbol &&
                            tokenAddress && (
                              <div>
                                <div className="mt-3 d-flex justify-content-between card-span">
                                  <span>Name</span>
                                  <span>{tokenName}</span>
                                </div>
                                <div className="mt-2 d-flex justify-content-between card-span">
                                  <span>Symbol</span>
                                  <span>{tokenSymbol}</span>
                                </div>
                                <div className="mt-2 d-flex justify-content-between card-span">
                                  <span>Decimal</span>
                                  <span>{tokenDecimal}</span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="col-12 mb-0">
                          <label>
                            Allocations<span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            style={{ height: "300px" }}
                            placeholder={
                              "Insert allocation: separate with breaks line. By format: address, amount or address amount.\nEx.\n0x0000000000000000000000000000000000001000 10\n0x0000000000000000000000000000000000001000 13.5"
                            }
                            onChange={handleAllocationChange}
                            value={allocationValue}
                            // style={{boxShadow: "none"}}
                          />
                        </div>
                        <Button
                          type="button"
                          variant="none"
                          className="btn default-btn"
                          style={{ margin: "30px auto" }}
                          onClick={(e) => {
                            shouldApprove
                              ? handleApprove(e)
                              : handleSendTokens(e);
                          }}
                          loading={txLoading}
                        >
                          {shouldApprove ? "Approve" : "Send Tokens"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
