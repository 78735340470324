import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { FaRocket, FaShieldAlt, FaLock, FaDropbox } from "react-icons/fa";

import { usePadStatus, usePoolListStats } from "./helper/useStats";

import "./Home.css";
import logo from "../../images/logo.png";
import TokenList from "../../component/TokenList";

export default function Home() {
  const stats = usePadStatus();

  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 10,
    loading: true,
  });
  const poolStats = usePoolListStats(updater);

  return (
    <React.Fragment>
      <section id="home" className="project-area pt-0">
        <div className="row mt-3 mt-md-5 align-items-top">
          <div className="col-md-6 mt-3 mt-md-5">
            <div className="card card-border-white">
              <div>
                <h3>
                  <span>Slap A Meme</span> - Launchpad
                </h3>
                <p>The launchpad you deserve is here!</p>
                <p>
                  Innovating the EVM ecosystem through the SlapPad Defi
                  Launchpad!
                </p>
              </div>
              <div className="mt-2">
                <Link to="/presale">
                  <button className="btn">Start Now</button>
                </Link>
                <Link to="/sale-list">
                  <button className="btn">Show List</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 mt-5">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="card card-border-gray text-center mb-2">
                  <h4>{stats.totalProjects}</h4>
                  <p>Total Projects</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card card-border-gray text-center mb-2">
                  <h4>{stats.totalParticipants}</h4>
                  <p>Total Participants</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card card-border-gray text-center mb-2">
                  <h4>${stats.totalLiquidityRaised}</h4>
                  <p>Total Liquidity Raised</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card card-border-gray text-center mb-2">
                  <h4>${stats.totalValueLocked}</h4>
                  <p>Total Value Locked</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mx-5" />
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <div className="card card-border-gray">
              <h5>
                <FaRocket />
                Fair launch
              </h5>
              <span>
                A fair launch is the process through which project creators
                introduce a new tokens without engaging in pre-sale.
              </span>
              <div className="d-flex mt-2">
                <Link to="/fairlaunch">
                  <button className="btn">Start Now</button>
                </Link>
                <Link to="/sale-list">
                  <button className="btn">Show List</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <div className="card card-border-gray">
              <h5>
                <FaShieldAlt />
                Private sale
              </h5>
              <span>
                A private sale refers to the sale of tokens to a select group of
                individuals or entities before public.
              </span>
              <div className="d-flex mt-2">
                <Link to="/privatesale">
                  <button className="btn">Start Now</button>
                </Link>
                <Link to="/prvsale-list">
                  <button className="btn">Show List</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <div className="card card-border-gray">
              <h5>
                <FaLock />
                Token Locker
              </h5>
              <span>
                The term token lockup refers to a specific period of time in
                which tokens cannot be transacted or traded.
              </span>
              <div className="d-flex mt-2">
                <Link to="/lock">
                  <button className="btn">Lock Now</button>
                </Link>
                <Link to="/token-locked">
                  <button className="btn">Show List</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <div className="card card-border-gray">
              <h5>
                <FaDropbox />
                Airdrop
              </h5>
              <span>
                A crypto airdrop is a marketing stunt in which a project makes
                an deposit as a way to grab attention for their token.
              </span>
              <div className="d-flex mt-2">
                <Link to="/airdrop">
                  <button className="btn">Airdrop Now</button>
                </Link>
                <Link to="/airdrop-list">
                  <button className="btn">Show List</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <Tabs defaultActiveKey="promoted" id="fill-tab-example">
              <Tab eventKey="promoted" title="Promoted">
                <TokenList poolStats={poolStats} />
              </Tab>
              <Tab eventKey="new-listings" title="New Listings">
                <TokenList poolStats={poolStats} />
              </Tab>
              <Tab eventKey="best-sales" title="Best Sales">
                <TokenList poolStats={poolStats} />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center">
          <h3 className="text-uppercase col-12 col-md-10 text-center">
            A Suite of Tools for Token Sales.
          </h3>
          <p className="col-12 col-md-10 text-center">
            A suite of tools were built to help you create your own tokens and
            launchpads in a fast, simple and cheap way, with no prior code
            knowledge required and 100% decentralized!
          </p>
        </div>
        <div className="row my-5">
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Standard</h5>
              <p className="">
                Create standard tokens on ETH, BSC, AVAX, Fantom, Polygon.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Deflationary</h5>
              <p>
                Create deflationary tokens with tax and/or charity functions.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Customization</h5>
              <p>Create a token sale for your own custom token easily.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Launchpad</h5>
              <p>
                Use the token you mint to create a launchpad with just a few
                clicks
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Branding</h5>
              <p className="">
                Adding logo, social links, description, listing on SlapPad.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Management</h5>
              <p>
                Portal to help you easily update content for your launchpad.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Community</h5>
              <p>Promote your launchpad to thousands of buyers on SlapPad.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
              <img src={logo} alt="Brand Logo" width="50px" />
              <h5 className="my-2">Locking</h5>
              <p>Lock your liquidity to Flamez after presale.</p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <p className="text-center">
            Disclaimer: The information provided shall not in any way constitute
            a recommendation as to whether you should invest in any product
            discussed. We accept no liability for any loss occasioned to any
            person acting or refraining from action as a result of any material
            provided or published.
          </p>
        </div>
      </section>
    </React.Fragment>
  );
}
