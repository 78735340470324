import React from "react";
import Badge from "react-bootstrap/Badge";
import { useTokenInfo } from "../pages/launchpadApply/SaleList/helper/useStats";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TokenRow = function ({ index, chainId = 56, rowdata }) {
  const tokenInfo = useTokenInfo(rowdata.token, chainId);
  return (
    <tr>
      <td>{index}</td>
      <td>
        <img src={rowdata.logourl} alt="Token Logo" width="30px" />
      </td>
      <td>{tokenInfo.name}</td>
      <td>
        <div className="d-flex align-items-center">
          <img
            src={require("../images/binance-coin.png").default}
            alt="Token Logo"
            width="20px"
          />
          <span className="ml-1">BSC</span>
        </div>
      </td>
      <td>
        <Badge bg="primary">KYC</Badge>
        <Badge bg="secondary">Audit</Badge>
      </td>
      <td>
        {rowdata.poolType === "0" && <Badge bg="info">Presale-sale</Badge>}
        {rowdata.poolType === "1" && <Badge bg="danger">Private-sale</Badge>}
        {rowdata.poolType === "2" && <Badge bg="success">Fairlaunch</Badge>}
      </td>
      <td className="text-right">
        <Link
          to={
            rowdata.poolType === "0"
              ? `/presale-details/${rowdata.poolAddress}${
                  chainId ? `?chainid=${chainId}` : ""
                }`
              : rowdata.poolType === "1"
              ? `/private-details/${rowdata.poolAddress}${
                  chainId ? `?chainid=${chainId}` : ""
                }`
              : rowdata.poolType === "2"
              ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${chainId}`
              : "/"
          }
          className="btn"
        >
          View Pool
        </Link>
      </td>
    </tr>
  );
};

export const TokenList = function ({ poolStats }) {
  let count = 1;

  return (
    <React.Fragment>
      <div className="table-responsive card no-hover p-0">
        {poolStats.loading ? (
          <p className="mx-auto my-5">Loading</p>
        ) : poolStats.poolList.length > 0 ? (
          <table
            className="table table-borderless"
            cellSpacing="0"
            cellPadding="0"
            style={{ border: "none", color: "#fff" }}
          >
            <thead>
              <tr>
                <td>#</td>
                <td>Coin</td>
                <td>Name</td>
                <td>Chain</td>
                <td>Badge</td>
                <td>Type</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {poolStats.poolList
                .slice(0)
                .reverse()
                .map((rowdata, index) => {
                  if (rowdata.poolState !== "2") {
                    return (
                      <TokenRow key={index} index={count++} rowdata={rowdata} />
                    );
                  }
                })}
            </tbody>
          </table>
        ) : (
          <p className="mx-auto my-5">No pool found</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default TokenList;
