import React from "react";
import StakingCard from "../../component/StakingCard";

export default function StakingList() {
  return (
    <React.Fragment>
      <section className="project-area explore-area">
        <div className="intro">
          <div className="intro-content text-center">
            <span className="intro-text">Staking List</span>
          </div>
        </div>

        <div className="row items">
          <React.Fragment>
            <StakingCard />
          </React.Fragment>
        </div>
      </section>
    </React.Fragment>
  );
}
