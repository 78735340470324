import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import { ethers } from "ethers";
import { useLocation } from "react-router";
import { getChainInfo, getSubgraphUrl } from "../../../hooks/network";
import { getApolloClient } from "../../../utils/apolloHelpers";
import { currencies } from "../../../hooks/currencies";

// export const GETPOOLS_QUERY = gql`
//   query getPools {
//     pools {
//       totalValueRaised
//       participants
//       liquidityRaised
//     }
//   }
// `;

export const GETPOOLS_QUERY = gql`
  query getPools {
    pools {
      poolAddress
      currencyAddress
      endTime
      hardCap
      liquidityListingRate
      liquidityPercent
      liquidityRaised
      maxContribution
      minContribution
      participants
      poolDetails
      poolState
      poolType
      rate
      softCap
      startTime
      token
      totalRaised
      totalValueRaised
      createdBy
    }
  }
`;

export const usePadStatus = (updater) => {
  const context = useWeb3React();
  const { chainId } = context;

  const [stats, setStats] = useState({
    totalLiquidityRaised: 0,
    totalProjects: 0,
    totalParticipants: 0,
    totalValueLocked: 0,
  });

  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");
  const { _chainId_ } = getChainInfo(queryChainId, chainId);

  const apolloClient = getApolloClient(getSubgraphUrl(_chainId_));

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await apolloClient.query({
          query: GETPOOLS_QUERY,
          variables: {},
        });
        let _pools = cloneDeep(response.data["pools"]);
        if (_pools.length > 0) {
          let _totalLiquidityRaised = 0;
          let _totalProjects = _pools.length;
          let _totalParticipants = 0;
          let _totalValueLocked = 0;
          for (let i = 0; i < _pools.length; i++) {
            _totalLiquidityRaised =
              _totalLiquidityRaised +
              Number(ethers.utils.formatEther(_pools[i].liquidityRaised));
            _totalParticipants =
              _totalParticipants + Number(_pools[i].participants);
            _totalValueLocked =
              _totalValueLocked +
              Number(ethers.utils.formatEther(_pools[i].totalValueRaised));
          }
          setStats({
            totalLiquidityRaised: _totalLiquidityRaised,
            totalProjects: _totalProjects,
            totalParticipants: _totalParticipants,
            totalValueLocked: _totalValueLocked,
          });
        } else {
          setStats({
            totalLiquidityRaised: 0,
            totalProjects: 0,
            totalParticipants: 0,
            totalValueLocked: 0,
          });
        }
      } catch (err) {
        toast.error(err.reason);
      }
    };

    if (apolloClient) {
      fetch();
    } else {
      setStats({
        totalLiquidityRaised: 0,
        totalProjects: 0,
        totalParticipants: 0,
        totalValueLocked: 0,
      });
    }
    // eslint-disable-next-line
  }, [updater, _chainId_]);

  return stats;
};

export const usePoolListStats = (updater) => {
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const { chainId, account } = context;
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  const { _chainId_ } = getChainInfo(queryChainId, chainId);

  const getCurrencyList = (currencies) => {
    let currencyList = [];
    currencies.map((currency, index) => {
      currencyList[currency.address] = currency.symbol;
    });
    return currencyList;
  };

  let currencyList = getCurrencyList(
    currencies[_chainId_] !== undefined
      ? currencies[_chainId_]
      : currencies["default"]
  );

  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    poolList: [],
    loading: true,
    chainId: _chainId_,
  });

  const apolloClient = getApolloClient(getSubgraphUrl(_chainId_));

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await apolloClient.query({
          query: GETPOOLS_QUERY,
          variables: {
            first: pageSize,
            skip: page * pageSize,
          },
        });
        let _pools = cloneDeep(response.data["pools"]);

        if (_pools.length > 0) {
          Promise.all(
            _pools.map(async (value) => {
              return {
                decimals: 18,
                name: "",
                symbol: "",
                poolAddress: value.poolAddress,
                currencyAddress: value.currencyAddress,
                currencySymbol:
                  currencyList[value.currencyAddress.toLowerCase()],
                endTime: value.endTime,
                hardCap: Number(ethers.utils.formatUnits(value.hardCap, 18)),
                liquidityListingRate: Number(
                  ethers.utils.formatUnits(
                    value.liquidityListingRate,
                    value.decimals
                  )
                ),
                liquidityPercent: value.liquidityPercent,
                maxContribution: Number(
                  ethers.utils.formatUnits(value.maxContribution, 18)
                ),
                minContribution: Number(
                  ethers.utils.formatUnits(value.minContribution, 18)
                ),
                poolState: value.poolState.toString(),
                poolDetails: value.poolDetails,
                poolType: value.poolType.toString(),
                rate: value.rate,
                softCap: Number(ethers.utils.formatUnits(value.softCap, 18)),
                startTime: value.startTime,
                token: value.token,
                totalRaised: Number(
                  ethers.utils.formatUnits(value.totalRaised, 18)
                ),
                percentageRaise:
                  (value.totalRaised /
                    Math.pow(10, 18) /
                    (value.poolType === 2
                      ? Number(ethers.utils.formatUnits(value.softCap, 18))
                      : Number(ethers.utils.formatUnits(value.hardCap, 18)))) *
                  100,
                logourl: value.poolDetails.toString().split("$#$")[0],
                bannerurl: value.poolDetails.toString().split("$#$")[1],
              };
            })
          ).then((result) => {
            setStats({
              getTotalNumberOfPools: _pools.length - 1,
              poolList: result,
              page: page,
              pageSize: pageSize,
              loading: !loading,
              chainId: _chainId_,
            });
          });
        } else {
          setStats({
            getTotalNumberOfPools: 0,
            page: page,
            pageSize: pageSize,
            poolList: [],
            loading: false,
            chainId: _chainId_,
          });
        }
      } catch (err) {
        toast.error(err.reason);
        // history.push("/");
      }
    };

    if (apolloClient) {
      fetch();
    } else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        poolList: [],
        loading: false,
        chainId: _chainId_,
      });
    }
    // eslint-disable-next-line
  }, [account, updater, _chainId_]);

  return stats;
};
