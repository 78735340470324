import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import styled from "styled-components";

const LayoutContainer = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1240px;
  padding-left: 60px;
  margin: 0px auto;
  height: calc(100vh - 54px);
  overflow-y: auto !important;

  @media screen and (max-width: 425px) {
    padding-left: 0px;
  }
`;

const MainLayout = (props) => {
  return (
    <>
      <Sidebar />
      <LayoutContainer>
        <Header />
        <MainContent>{props.children}</MainContent>
      </LayoutContainer>
    </>
  );
};

export default MainLayout;
