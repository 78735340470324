import React from "react";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import styled from "styled-components";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { FaSearch } from "react-icons/fa";

const StyledInputGroup = styled(InputGroup)`
  display: flex;
  width: 400px;
  max-width: 100%;

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const SearchResultBox = styled.div`
  width: 400px;
  max-width: 100%;
  max-height: 200px;
  position: absolute;
  background: #010101f7;
  padding: 5px 5px;
  margin-top: 10px;
  border: 1px solid #d3d3d361;
  border-radius: 5px;
  overflow-y: auto;
`;

const SearchItem = () => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex align-items-center">
        <img
          src="http://localhost:3000/static/media/logo.620311e0.png"
          width="30px"
          alt="Token Logo"
        />
        <div className="mx-2">
          <span className="d-flex">
            Slap A Meme
            <p className="ml-1 text-lightdark">( Slap )</p>
          </span>
          <p>0x123123...asdfasdf</p>
        </div>
      </div>
      <button className="btn">view</button>
    </div>
  );
};

export default function SearchBox() {
  const [searchStatus, setSearchStatus] = useState(false);

  const [keyword, setKeyword] = useState("");

  const getSearchResult = () => {
    if (keyword.length === 0)
      return (
        <p className="ml-1 text-yellow">Enter keywords to find your pool.</p>
      );

    return (
      <>
        <p className="ml-1 text-yellow">Loading...</p>
        {/* <SearchItem />
        <SearchItem />
        <SearchItem />
        <SearchItem />
        <SearchItem />
        <SearchItem /> */}
      </>
    );
  };

  return (
    <React.Fragment>
      <div>
        <OutsideClickHandler onOutsideClick={() => setSearchStatus(false)}>
          <StyledInputGroup onFocus={() => setSearchStatus(true)} size="sm">
            <InputGroup.Text id="basic-addon1">
              <FaSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Type token symbol, address to find your launchpad"
              aria-label="addr"
              aria-describedby="basic-addon1"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </StyledInputGroup>
          {searchStatus === true && (
            <SearchResultBox>{getSearchResult()}</SearchResultBox>
          )}
        </OutsideClickHandler>
      </div>
    </React.Fragment>
  );
}
