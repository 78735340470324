import React, { useState } from "react";
import Button from "react-bootstrap-button-loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import question from "../../images/question.png";

export default function StakingDetail() {
  const [refcopy1, setRefcopy1] = useState(false);

  return (
    <React.Fragment>
      <div className="detail-page mt-3">
        <section className="item-details-area">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="card project-card no-hover py-4 px-2">
                <div className="row">
                  <div className="col-12 my-2 d-flex justify-content-start align-items-center">
                    <img
                      height="auto"
                      src={question}
                      style={{
                        width: "35px",
                        height: "35px",
                        padding: "5px",
                        border: "1px solid white",
                        borderRadius: "50%",
                      }}
                      alt="question icon"
                    />
                    <div>
                      <h4 className="m-0 ml-3">Slap</h4>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Pool Address</p>
                    <p>
                      0x38629604dC25f616689233bf02c8CC9d8663Bb9e
                      <CopyToClipboard
                        text="0x38629604dC25f616689233bf02c8CC9d8663Bb9e"
                        onCopy={() => {
                          setRefcopy1(true);
                          setTimeout(() => {
                            setRefcopy1(false);
                          }, 2000);
                        }}
                      >
                        <img
                          className="ml-2"
                          src={require("../../images/icon.png").default}
                          alt="project"
                        />
                      </CopyToClipboard>
                      <span>{refcopy1 && "copied"}</span>
                    </p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Token Address</p>
                    <p>
                      0x38629604dC25f616689233bf02c8CC9d8663Bb9e
                      <CopyToClipboard
                        text="0x38629604dC25f616689233bf02c8CC9d8663Bb9e"
                        onCopy={() => {
                          setRefcopy1(true);
                          setTimeout(() => {
                            setRefcopy1(false);
                          }, 2000);
                        }}
                      >
                        <img
                          className="ml-2"
                          src={require("../../images/icon.png").default}
                          alt="project"
                        />
                      </CopyToClipboard>
                      <span>{refcopy1 && "copied"}</span>
                    </p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>APR</p>
                    <p>TBD</p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Total Tokens Staked</p>
                    <p>0</p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Numb of Stakers</p>
                    <p>0</p>
                  </div>
                </div>
              </div>
              <div className="card project-card no-hover py-4 px-2">
                <div className="row">
                  <div className="col-12 my-2 d-flex justify-content-start align-items-center">
                    <h5 className="m-0">Rewards</h5>
                  </div>
                </div>
                <div className="row mt-2"></div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card project-card no-hover">
                <div className="mt-3 px-3">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button className="p-2 bg-transparent text-white border border-left-0 border-right-0 border-top-0 border-warning text-warning">
                        Stake
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button className="p-2 bg-transparent text-white border-0 border-bottom">
                        Withdraw
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="staking-area">
                    <div className="staking-card single-staking">
                      <p>Amount</p>
                      <div className="input-box my-1">
                        <div className="input-area d-flex flex-column">
                          <div className="input-text">
                            <input type="text" value={1} placeholder="0.00" />
                            <a href="#sec">Max</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex justify-content-between mt-2">
                        <p>Withdrawable At</p>
                        <p></p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Your Staking</p>
                        <p>0 VOTE</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Boost Option</p>
                        <p>-</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Min Staking Amount</p>
                        <p>10,000 SLAP</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Max Staking Amount</p>
                        <p>1,000,000 SLAP</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Min Staking Period</p>
                        <p>1 weeks</p>
                      </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                      <Button variant="none" type="button" className="btn">
                        Approve
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
