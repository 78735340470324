import React from "react";

export default function BoostSetting() {
  return (
    <div className="row mb-1">
      <div className="col-md-3 mb-0">
        <select
          className="form-select"
          defaultValue="1"
          name="refund"
          aria-label="Default select example"
          style={{ height: "38px" }}
        >
          <option value="0">days</option>
          <option value="1">weeks</option>
          <option value="1">months</option>
          <option value="1">years</option>
        </select>
      </div>
      <div className="col-md-5 mb-0">
        <input
          className="form-control"
          type="text"
          name="softcap"
          placeholder="duration (months)"
        />
      </div>
      <div className="col-md-3 mb-0">
        <input
          className="form-control"
          type="text"
          name="softcap"
          placeholder="percentage (%)"
        />
      </div>
      <div className="col-md-1 mb-0">
        <button className="btn btn-bordered mt-0 px-0 w-100">X</button>
      </div>
    </div>
  );
}
